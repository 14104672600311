import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import clsx from 'clsx';
import { LABEL } from '@core/constants';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Title from '@components/Title';
import * as styles from './404.module.scss';


type DataType = {
  image: ImageDataLike;
}

type Props = {
  default?: boolean;
}

export default function NotFoundPage(props: Props) {
  const data = useStaticQuery<DataType>(graphql`
      query NotFoundPage {
          image: file(
              absolutePath: { glob: "**/src/images/not-found.jpg" }
          ) {
              childImageSharp {
                  gatsbyImageData(
                      layout: CONSTRAINED
                      aspectRatio: 1 #0.5625
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                  )
              }
          }
      }
  `);

  const image = getImage(data.image);

  const renderContent = () => (
    <div className={styles.container}>
      {image && (
        <GatsbyImage
          className={styles.image}
          image={image}
          alt="Background image"
        />
      )}
      <div className={styles.inner}>
        <div className={styles.content}>
          <span className={styles.error}>404<sup>not found</sup></span>
          <Title className={styles.title}>Oops! Can't find this page.</Title>
          <p className={styles.paragraph}>No worries - we can still get you where you need to go. Our homepage is a good place to start.</p>
          <Link to="/" className={clsx('button large light stretch', styles.link)}>Go home</Link>
        </div>
      </div>
    </div>
  );

  if (props.default) {
    return renderContent();
  }

  return (
    <Main>{renderContent()}</Main>
  );
}

export function Head() {
  return <BaseHead title={LABEL.NOT_FOUND}/>;
}
