import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { isArray } from 'lodash';
import { getSiteMetaData } from '@core/utils';
import { SiteData, OpenGraph } from '@interface/gatsby';


type Props = {
  description?: string;
  title?: string | string[];
  keywords?: string;
  opengraph?: OpenGraph;
  twitter?: OpenGraph;
};

export default function Head(props: Props) {
  const componentId = React.useId();
  const data = useStaticQuery<SiteData>(graphql`
      query SeoMetadata {
          site {
              siteMetadata {
                  brand
                  siteUrl
                  meta {
                      title
                      description
                      keywords
                  }
                  og {
                      siteName
                      twitterCreator
                  }
              }
          }
          opengraphImage: file(
              absolutePath: { glob: "**/src/images/opengraph.jpg" }
          ) {
              childImageSharp {
                  opengraph: resize(cropFocus: CENTER, fit: COVER, width: 1200, height: 630, toFormat: NO_CHANGE, quality: 100) {
                      src
                      width
                      height
                  }
              }
          }
          twitterImage: file(
              absolutePath: { glob: "**/src/images/opengraph.jpg" }
          ) {
              childImageSharp {
                  twitter: resize(cropFocus: CENTER, fit: COVER, width: 1200, height: 600, toFormat: NO_CHANGE, quality: 100) {
                      src
                      width
                      height
                  }
              }
          }
      }
  `);
  const location = useLocation();
  const { brand, meta, siteUrl, og } = getSiteMetaData(data);

  const ogImage = props.opengraph ?? data.opengraphImage!.childImageSharp.opengraph;
  const twImage = props.twitter ?? data.twitterImage!.childImageSharp.twitter;
  const title = props.title ? isArray(props.title) ? props.title.join(' / ') : props.title : meta?.title;
  const description = props.description ?? meta?.description;
  const keywords = props.keywords ?? meta?.keywords;

  const metas = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'keywords',
      content: keywords,
    },
    {
      name: 'og:image',
      content: `${siteUrl}${ogImage.src}`,
    },
    {
      name: 'og:image:width',
      content: `${ogImage!.width}`,
    },
    {
      name: 'og:image:height',
      content: `${ogImage!.height}`,
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:site_name',
      content: og!.siteName,
    },
    {
      name: 'og:url',
      content: `${siteUrl}${location.pathname}`,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:image',
      content: `${siteUrl}${twImage.src}`,
    },
    {
      name: 'twitter:creator',
      content: og!.twitterCreator,
    },
    {
      name: 'author',
      content: brand,
    },
    {
      name: 'copyright',
      content: `Copyright by ${brand}. 2017 - ${(new Date()).getFullYear()}`,
    },
  ];

  return (
    <>
      <meta name="language" content="en"/>
      {metas.map((meta, index) => (
        <React.Fragment key={`${componentId}-${index}`}>
          <meta name={meta.name} content={meta.content}/>
        </React.Fragment>
      ))}
      <link
        rel="preconnect"
        href="https://fonts.googleapis.com"
      />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        rel="preconnect"
        href="https://www.gstatic.com"
        crossOrigin="true"
      />
      <link
        rel="preconnect"
        href="https://accounts.google.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=fallback"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      <link rel="manifest" href="/site.webmanifest"/>
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#161616"/>
      <link rel="shortcut icon" href="/favicon.ico"/>
      <meta name="msapplication-TileColor" content="#161616"/>
      <meta name="msapplication-config" content="/browserconfig.xml"/>
      <meta name="theme-color" content="#161616"/>
      <title>{`${title} / ${brand}`}</title>
    </>
  );
}
