import * as React from 'react';
import clsx from 'clsx';
import ScrollWidthHolder from '@components/ScrollWidthHolder';
import * as styles from './Main.module.scss';


type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode | React.ReactNode[] | string;
  className?: string;
};

export default function Main({
  children,
  className,
  ...props
}: Props) {
  return (
    <ScrollWidthHolder
      {...props}
      tagName="main"
      className={clsx(styles.container, className)}
    >
      {children}
    </ScrollWidthHolder>
  );
}
