import * as React from 'react';
import clsx from 'clsx';
import * as styles from './Title.module.scss';


export type Props = {
  children: React.ReactNode;
  level?: 1 | 2 | 3 | 4 | 5;
  noMargin?: boolean;
  className?: string;
};

export default function Title({ children, className, level = 1, noMargin = false }: Props) {
  const props = {
    className: clsx(className, {
      [styles.h1]: level === 1,
      [styles.h2]: level === 2,
      [styles.h3]: level === 3,
      [styles.h4]: level === 4,
      [styles.h5]: level === 5,
      [styles.noMargin]: noMargin,
    }),
  };

  return React.createElement(`h${level}`, props, children);
}
